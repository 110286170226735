import React, { useState, useEffect } from "react";
import useApiAxios from "../api/useApiAxios";
import "./MarketContent.css"; // Import the CSS file for styling

const MarketContent = ({ marketAllContent }) => {
  const staticImagePath = process.env.REACT_APP_IMG_URL;
  const { getAllMarketContentApi } = useApiAxios();

  // State to manage modal visibility and selected market
  const [showModal, setShowModal] = useState(false);
  const [selectedMarket, setSelectedMarket] = useState(null);
  const [marketContentData, setMarketContentData] = useState(null);

  const createdById = marketAllContent?.data?.created_by[0];

  // Open modal to view selected market
  const openModal = (marketIndex) => {
    setSelectedMarket(marketIndex);
    setShowModal(true);
  };

  // Close modal
  const closeModal = () => {
    setShowModal(false);
    setSelectedMarket(null);
  };

  // Fetch Market Content
  useEffect(() => {
    const fetchMarketContent = async () => {
      if (createdById) {
        console.warn("Fetching data for createdById:", createdById);
        const { error, data } = await getAllMarketContentApi(createdById);
        if (!error && data) {
          setMarketContentData(data); // Set the data if there was no error
        } else {
          setMarketContentData(null); // Handle the case where the API returns an error
        }
      }
    };

    fetchMarketContent();
  }, [createdById]); // Re-run when createdById changes

  return (
    <div>
      {/* Hero section */}
      <section className="slider-area slider-area2">
        <div className="slider-active">
          <div className="single-slider slider-height2">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-xl-8 col-lg-11 col-md-12">
                  <div className="hero__caption hero__caption2 text-center">
                    <h1 data-animation="bounceIn" data-delay="0.2s">
                      Meet Our Markets
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Heading for Markets */}
      <div className="markets-header">
        <h2>Markets</h2>
        <p>Owned by the Market Owner</p>
      </div>

      <div className="mt-2">
        {/* Iterate through each market */}
        <div className="market-cards-grid">
          {marketContentData?.data?.map((market, marketIndex) => (
            <div
              key={marketIndex}
              className="market-card"
              onClick={() => openModal(marketIndex)} // Handle click to show modal
              style={{ cursor: "pointer" }}
            >
              <div className="card-image">
                <img
                  src={`${staticImagePath}/${market?.marketContent?.bannerImage}`}
                  alt="Market Banner"
                  className="market-card-image"
                />
              </div>
              <div className="card-title">
                <h3>{market.name}</h3>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Modal to show the details */}
      {showModal && selectedMarket !== null && marketContentData && (
        <div className="modal-overlay">
          <div className="modal-content">
            {/* Close button */}
            <button className="modal-close-btn" onClick={closeModal}>
              Close
            </button>

            <div className="modal-header">
              <h2>{marketContentData.data[selectedMarket].name}</h2>
            </div>

            {/* Modal Body - Display Market Details */}
            <div className="modal-body">
              <h1>
                {marketContentData.data[selectedMarket].marketContent.mainTitle}
              </h1>
              <h2>
                {marketContentData.data[selectedMarket].marketContent.title}
              </h2>

              {/* Market Description */}
              <div
                className="market-description"
                dangerouslySetInnerHTML={{
                  __html:
                    marketContentData.data[selectedMarket].marketContent
                      .description
                }}
              />

              {/* Footer Description */}
              <div
                className="footer-description"
                dangerouslySetInnerHTML={{
                  __html:
                    marketContentData.data[selectedMarket].marketContent
                      .footerDescription
                }}
              />

              {/* Terms and Conditions */}
              <div
                className="terms-conditions"
                dangerouslySetInnerHTML={{
                  __html:
                    marketContentData.data[selectedMarket].marketContent
                      .termsAndConditions
                }}
              />

              {/* Social Media Links */}
              <div className="social-links">
                {marketContentData.data[selectedMarket].marketContent
                  .facebookUrl && (
                  <a
                    href={
                      marketContentData.data[selectedMarket].marketContent
                        .facebookUrl
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    className="social-link"
                  >
                    Facebook
                  </a>
                )}
                {marketContentData.data[selectedMarket].marketContent
                  .instaUrl && (
                  <a
                    href={
                      marketContentData.data[selectedMarket].marketContent
                        .instaUrl
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    className="social-link"
                  >
                    Instagram
                  </a>
                )}
                {marketContentData.data[selectedMarket].marketContent
                  .twitterUrl && (
                  <a
                    href={
                      marketContentData.data[selectedMarket].marketContent
                        .twitterUrl
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    className="social-link"
                  >
                    Twitter
                  </a>
                )}
                {marketContentData.data[selectedMarket].marketContent
                  .tiktokUrl && (
                  <a
                    href={
                      marketContentData.data[selectedMarket].marketContent
                        .tiktokUrl
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    className="social-link"
                  >
                    TikTok
                  </a>
                )}
              </div>

              {/* Categories */}

              {/* Subcategories */}
              <div className="categories-subcategories-row">
                {/* Categories Section */}
                <div className="categories">
                  <h3>Categories:</h3>

                  {marketContentData.data[selectedMarket].subCategory.length ? (
                    <ul>
                      {marketContentData.data[selectedMarket].subCategory.map(
                        (subCat, index) => (
                          <li key={index}>{subCat.name}</li> // Access the 'name' property of the subcategory
                        )
                      )}
                    </ul>
                  ) : (
                    <p>No Categories available</p>
                  )}
                </div>

                {/* Subcategories Section */}
                <div className="subcategories">
                  <h3>Sub Categories:</h3>
                  {marketContentData.data[selectedMarket].category.length ? (
                    <ul>
                      {marketContentData.data[selectedMarket].category.map(
                        (cat, index) => (
                          <li key={index}>{cat.name}</li> // Access the 'name' property of the category
                        )
                      )}
                    </ul>
                  ) : (
                    <p>No Sub Categories available</p>
                  )}
                </div>
              </div>

              {/* Market Dates */}
              <div className="market-dates">
                <h3>Market Dates:</h3>
                {marketContentData.data[selectedMarket].marketDate.length ? (
                  <ul>
                    {marketContentData.data[selectedMarket].marketDate.map(
                      (dateInfo, dateIndex) => {
                        // Format the date to "day month year" (e.g., 12 October 2024)
                        const formattedDate = new Date(
                          dateInfo.date
                        ).toLocaleDateString("en-GB", {
                          day: "2-digit",
                          month: "long",
                          year: "numeric"
                        });

                        return <li key={dateIndex}>{formattedDate}</li>;
                      }
                    )}
                  </ul>
                ) : (
                  <p>No market dates available</p>
                )}
              </div>

              {/* Sections */}
              <div className="market-sections">
                <h3>Sections</h3>
                <div className="section-columns">
                  {marketContentData.data[selectedMarket].marketContent.section
                    .length ? (
                    marketContentData.data[
                      selectedMarket
                    ].marketContent.section.map((section, index) => (
                      <div key={index} className="section-column">
                        <img
                          src={`${staticImagePath}/${section.image}`}
                          alt={section.title}
                          className="section-image"
                        />
                        <h4
                          dangerouslySetInnerHTML={{
                            __html: section.description
                          }}
                        ></h4>
                        <div
                          className="section-description"
                          dangerouslySetInnerHTML={{
                            __html: section.description
                          }}
                        />
                      </div>
                    ))
                  ) : (
                    <p>No sections available</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MarketContent;
