import React, { useEffect, useState } from "react";
import useApiAxios from "../api/useApiAxios";
import { useSearchParams } from "react-router-dom";
import VendorModal from "../component/modal/vendorModal";

const MarketMap = () => {
  const { getMarketVendorMapApi, getVendorAfterDateChangeApi } = useApiAxios();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  const [marketData, setMarketData] = useState(null);
  const [selectedDate, setSelectedDate] = useState(); // Default value as  for "Select Date"
  const [selectedMapImage, setSelectedMapImage] = useState(null);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const staticImagePath = process.env.REACT_APP_IMG_URL;
  const dummyImagePath = "assets/img/dummyImage.avif"; // Default map image path
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [vendor, setVendor] = useState([]);
  console.log(marketData?.marketData?.marketDate);
  useEffect(() => {
    const fetchVendors = async () => {
      try {
        const response = await getMarketVendorMapApi(id);
        setMarketData(response.data.data);
        if (response.data.data.marketData.marketDate.length > 0) {
          // Set the default map image on initial load
          setSelectedMapImage(response.data.data.marketData.mapImage);
        }
      } catch (error) {
        console.error(error);
      }
    };

    if (id) {
      fetchVendors();
    }
  }, [id]);
  console.log(vendor);
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const handleDateChange = async (event) => {
    const selectedDateId = event.target.value;
    setSelectedDate(selectedDateId);
  
    if (selectedDateId === "") {
      setSelectedMapImage(marketData.marketData.mapImage);
      setVendor([]); // Clear vendors if no date is selected
    } else {
      try {
        const getVendorAfterDateChange = await getVendorAfterDateChangeApi(selectedDateId);
        setVendor(getVendorAfterDateChange?.data?.data || []);
  
        const selectedDateData = marketData.marketData.marketDate.find(
          (dateItem) => dateItem._id === selectedDateId
        );
  
        if (selectedDateData) {
          setSelectedMapImage(selectedDateData.mapImages || marketData.marketData.mapImage);
        }
      } catch (error) {
        console.error("Error fetching vendors after date change:", error);
      }
    }
  };
  
  const handleVendorClick = (vendor) => {
    setSelectedVendor(vendor);
    setIsModalOpen(true); // Open modal
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedVendor(null); // Clear selected vendor
  };
  
  return (
    <div>
      <section className="slider-area slider-area2">
        <div className="slider-active">
          {/* Single Slider */}
          <div className="single-slider slider-height2">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-xl-10">
                  <div className="hero__caption hero__caption2 text-center">
                    <h1 data-animation="bounceIn" data-delay="0.2s">
                      Please scroll down to view the{" "}
                      {marketData?.marketData?.name} Market Maps
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="our-services form-contact">
        <div className="container">
          {/* Card Open */}
          <div className="card mb-5">
            <div className="card-header">
              <h2 className="font-weight-bold mb-20">
                {marketData?.marketData?.name}
              </h2>
              <div className="row">
                {/*/Col*/}
                <div className="col-md-3 col-sm-4 mb-3">
                  <label>Select Date</label>
                  <select
                    className="form-control"
                    value={selectedDate}
                    onChange={handleDateChange}
                  >
                    <option value="">Select Date</option>
                    {marketData &&
                      marketData.marketData.marketDate.map((dateItem) => (
                        <option key={dateItem._id} value={dateItem._id}>
                          {formatDate(dateItem.date)}
                        </option>
                      ))}
                  </select>
                </div>
                {/*/Col*/}
              </div>
              {/*/Row*/}
            </div>
            <div className="card-body">
              {marketData && (
                <div className="map-container">
                  <img
                    src={`${staticImagePath}/${selectedMapImage}`}
                    alt="Market Map"
                    className="w-100"
                  />
                  <div className="map-data-overlay mt-4">
                    {selectedDate
                      ? vendor.map((vendor, index) => (
                          <a
                            key={vendor?.vendor?._id}
                            href="#"
                            className="genric-btn primary-border circle m-1 "
                            data-toggle="modal"
                            data-target="#profileDetailsModal"
                            onClick={(e) => {
                              e.preventDefault();
                              handleVendorClick(vendor?.vendor); // Show vendor details in modal
                            }}
                          >
                            <span className="badge badge-warning badge-pill mr-2">
                              {index + 1}
                            </span>
                            {vendor?.vendor?.businessName}
                          </a>
                        ))
                      : marketData.vendors.map((vendor, index) => (
                          <a
                            key={vendor._id}
                            href="#"
                            className="genric-btn primary-border circle m-1 "
                            data-toggle="modal"
                            data-target="#profileDetailsModal"
                            onClick={(e) => {
                              e.preventDefault();
                              handleVendorClick(vendor); // Show vendor details in modal
                            }}
                          >
                            <span className="badge badge-warning badge-pill mr-2">
                              {index + 1}
                            </span>
                            {vendor.businessName}
                          </a>
                        ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
      <VendorModal
        vendor={selectedVendor}
        isOpen={isModalOpen}
        onClose={handleCloseModal}
      />
    </div>
  );
};

export default MarketMap;
